<script>
  import appConfig from "@/app.config";
  // import PageHeader from "@/components/page-header";

  export default {
    page: {
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    },
    components: {
      // PageHeader,
    },
    data() {
      return {
        users: [],
        page: 1,
        limit: 20,
        tot_pages: 0,
        searchUsers: null,
        SearchMode: false,
        user_info: {},
        data: {},
        district:{}

        // appprices:[]
      };
    },
    methods: {
      editMarketingArea() {
        console.log(this.user_info);
        this.http
          .put("marketing-areas", this.user_info.id, {
            name: this.user_info?.name,
          })
          .then((res) => {
            if (res.status) {
              this.get(this.page);
             
              document.querySelector("#editModal .close-modal-btn")?.click()
              this.popup.alert({ title: '', msg: 'popups.success' })

              
            }
          });
      },
      addMarketingArea() {
        this.http.post("marketing-areas", this.data, null, true).then((res) => {
          if (res.status) {
            
            this.data = {};
            this.get(this.page);
        
            document.querySelector("#addModal .close-modal-btn").click()
            this.popup.alert({ title: '', msg: 'popups.success' })

          }
        });
      },
      search() {
        this.SearchMode = true;
        console.log(this.searchUsers);
        this.tot_pages = 0;
        this.http
          .post("marketing-areas/search-by-district-id", {
            limit: this.limit,
            page: this.page,
            search: this.searchUsers,
            marketing_district_id:this.$route.params.id,
            col: "name",
          },null,true)
          .then((res) => {
            this.users = res.data;
          });
      },
      cancelSearchMode() {
        this.searchUsers = "";
        this.SearchMode = false;
        this.get(this.page);
      },
      deleteUser(user) {
        var data = {
          title: "popups.deleteuser",
          msg: "popups.deletemsg",
          buttons: [
            {
              text: "Delete",
              closer: true,
              color: "danger",
            },
            {
              text: "Cancel",
              closer: true,
              color: "light",
              handler: () => {
                console.log("Like Clicked");
              },
            },
          ],
        };
        this.popup.confirm(data).then((resp) => {
          if (resp) {
            this.http.delete("marketing-areas", user.id).then(() => {
              this.get(this.page);
            });
          }
        });
      },
      get(page) {
        
        this.http
          .post("marketing-areas/pagination-by-district-id", {
            limit: this.limit,
            page: page,
            marketing_district_id:this.$route.params.id,
          })
          .then((res) => {
            this.page = page;
            this.tot_pages = Math.ceil(res.tot / this.limit);
            this.users = res.data;
          });
      },
      getDistrictById(){
this.http.get('marketing-districts/'+this.$route.params.id).then((res)=>{
  if(res.status)
  this.district = res.data
})
      },
      setUserInfo(user) {
        this.user_info = JSON.parse(JSON.stringify(user));
      },
    },
    created() {
      this.get(this.page);
    },
    mounted() {
      this.getDistrictById()
    },
  };
</script>

<template>
    <!-- <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.marketing.subItems.marketing_area_management')"
    /> -->
    <div class="d-flex align-items-center justify-content-between mb-4">
    <div class="d-flex align-items-center">
      <a href="javascript:void(0);" @click="$router.back()">
        <h4 class="m-0">
          {{ $t("menu.menuitems.marketing.subItems.marketing_districts_management") }}
        </h4>
      </a>
      <div class="mx-2 mb-0">\</div>
      <div>
        <h5 href="javascript:void(0)" class="mb-0">
  {{ $t("menu.menuitems.marketing.subItems.area") }}        </h5>
      </div>
      <div class="mx-2">\</div>
      <div>
        <a href="javascript:void(0)" >
          {{district?.name}}
        </a>
      </div>
      
    </div>
  </div>
    <div class="row">
      <div class="col-6">
        <div class="search-box chat-search-box" style="width: 250px">
          <div class="position-relative">
            <input
              @keyup="search()"
              type="text"
              class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
              :placeholder="$t('users.searchplaceholder')"
              v-model="searchUsers"
              style="background-color: #2a3042 !important"
            />
            <i class="bx bx-search-alt search-icon text-light"></i>
            <button
              @click="cancelSearchMode()"
              v-if="SearchMode"
              class="btn-close text-light"
              style="position: absolute; top: 12px; left: 250px"
            ></button>
          </div>
        </div>
      </div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button
          type="button"
          class="btn btn-light float-end mb-4"
          data-bs-toggle="modal"
          data-bs-target="#addModal"
        >
          <span
            class="bx bxs-plus-circle float-end fa-2x"
            style="color: #2a3042 !important"
          ></span>
        </button>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
          <th scope="col">{{ $t("users.userstable.id") }}</th>
          <th scope="col">{{ $t("marketing.team_members.table.name") }}</th>
          
          <th scope="col">{{ $t("users.userstable.operations") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center" v-for="(user, index) in users" :key="user">
          <td>{{ index + 1 }}</td>
          <td>{{ user?.name }}</td>
      
          <td>
            <button
              type="button"
              class="btn btn-primary me-2 btn-sm"
              data-bs-target="#editModal"
              data-bs-toggle="modal"
              @click="setUserInfo(user)"
            >
              {{ $t("popups.edit") }}
            </button>
            <a
              class="btn btn-danger btn-sm me-2"
              :class="{}"
              href="javascript:void(0)"
              role="button"
              @click="deleteUser(user)"
              >{{ $t("popups.delete") }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!--Start Add Modal-->
    <div
      class="modal fade"
      id="addModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="addMarketingArea()">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("popups.add") }}
              </h5>
              
            </div>

            <div class="modal-body">
              <label for="name">{{ $t("popups.name") }}</label>
              <input
                v-model="data.name"
                id="name"
                required
                class="form-control"
                type="text"
                :placeholder="$t('popups.name')"
              />
            </div>
            <div
              class="modal-footer"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <button type="submit" class="btn btn-primary">
                {{ $t("popups.add") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-danger close-modal-btn"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--End Add Modal-->

    <!-- Edit Modal -->
    <div
      class="modal fade"
      id="editModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="editMarketingArea()">
            <div class="modal-header" :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("popups.edit") }}
              </h5>
              <button
                type="button"
                class="btn-close-edit"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              >
                X
              </button>
            </div>

            <div class="modal-body" :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
              <label for="name">{{ $t("popups.name") }}</label>
              <input
                v-model="user_info.name"
                id="name"
                required
                class="form-control"
                type="text"
                :placeholder="$t('popups.name')"
              />
            </div>
            <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
              <button type="submit" class="btn btn-primary">
                {{ $t("popups.edit") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn close-modal-btn btn-danger"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--end Edit Modal-->
    <!--   Apps  Pagination     -->
    <ul
      v-if="tot_pages > 0"
      class="pagination pagination-rounded justify-content-center mb-2"
    >
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == total_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
    <!--end-->
</template>
